import { render, staticRenderFns } from "./PatientCard.vue?vue&type=template&id=22d9b946&scoped=true&"
import script from "./PatientCard.vue?vue&type=script&lang=js&"
export * from "./PatientCard.vue?vue&type=script&lang=js&"
import style0 from "./PatientCard.vue?vue&type=style&index=0&id=22d9b946&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22d9b946",
  null
  
)

export default component.exports